<template>
    <div
        :key="answerItem.question.id"
        class="mb-8"
        v-if="answerItem.answers && answerItem.answers.length > 0"
    >
        <template v-for="questionAnswer in answerItem.answers" >
            <response-details-question 
                :key="`question_text_${questionAnswer.id}`" 
                :index="index" :question="answerItem.question" 
                :loopIndex="questionAnswer.loop_index" 
                :loop_item="questionAnswer.loop_item"></response-details-question>
            <div :key="`question_answer_${questionAnswer.id}`" v-if="!['description-slide', 'file-upload'].includes(answerItem.question.type)" class="mr-5">
                    <audio-item  v-if="audioType.includes(answerItem.question.type)" :answer="{
                        ...questionAnswer, 
                        position: index+1,
                        // reuse the transcript from probes
                        transcript: probesGroupedByLoopIndex[questionAnswer.loop_index] &&  probesGroupedByLoopIndex[questionAnswer.loop_index].length &&  probesGroupedByLoopIndex[questionAnswer.loop_index][0].transcription && probesGroupedByLoopIndex[questionAnswer.loop_index][0].transcription.text || questionAnswer.transcript,
                    }"></audio-item>
                    <regular-item :key="questionAnswer.id" v-else :answer="{...questionAnswer, position: index+1}" :questionProperties="answerItem && answerItem.question && answerItem.question.properties"/>
                
            </div>
            <!-- Files -->
            <div v-else-if="answerItem.question.type==='file-upload'" :key="`question_answer_${questionAnswer.id}`">
                <div v-if="questionAnswer" class="mr-5">
                    <results-file-attachment  :answer="questionAnswer" :question="answerItem.question"></results-file-attachment>
                </div>
            </div>
    
            <!-- Probes -->
            <div
                v-for="(probe, index) in probesGroupedByLoopIndex[questionAnswer.loop_index]"
                :key="`probe_${probe.id}`"
                class="mb-2"
            >
                <!-- we need to avoid an answer form the first probe, because this will create a repeat with original answer -->
                <div v-if="index" class="mr-5">
                    <template v-if="probe.answer">
                        <audio-item disableLike :answer="{
                                file_url: probe.answer.url || null,
                                file_path: probe.answer.path || null,
                                transcript: probe.transcription && probe.transcription.text || null,
                                status: 'complete'
                            }"></audio-item>
                    </template>
                    <results-no-answer v-else></results-no-answer>
                </div>
                <probing-question v-if="!probe.sufficient || probe.failed" :failed="probe.failed" :followup="probe.followup" :question="answerItem.question" ></probing-question>
            </div>
        </template>
    </div>
</template>

<script>
import ResponseDetailsQuestion from './ResponseDetailsQuestion.vue';
import AudioItem from '../../../../components/answer/audio/ResultsAudioItem.vue';
import RegularItem from '../../../../components/answer/regular/ResultsRegularItem.vue';
import ResultsFileAttachment from '../../../../components/answer/regular/ResultsFileAttachment.vue';
import ResultsNoAnswer from '../../../../components/answer/regular/ResultsNoAnswer.vue'
import ProbingQuestion from './ProbingQuestion.vue';
import { VOICE_QUESTION, VOICE_RESPONSE } from '../../../../configs/questionTypes';

export default {
    components: { 
        ResponseDetailsQuestion,
        AudioItem,
        RegularItem,
        ResultsFileAttachment,
        ResultsNoAnswer,
        ProbingQuestion,
    },
    props: {
        answerItem: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            audioType: [VOICE_QUESTION, VOICE_RESPONSE]
        };
    },
 
    computed: {
        probesGroupedByLoopIndex(){

            return this.answerItem?.probes?.reduce((acc, probe) => {
                if (acc[probe.loop_index]) {
                    acc[probe.loop_index].push(probe);
                } else {
                    acc[probe.loop_index] = [probe];
                }
                return acc;
            }, {}) || {};
        }
    }
}
</script>